export const DIRECTIONS = {
  RIGHT: 'RIGHT',
  LEFT: 'LEFT',
  UP: 'UP',
  DOWN: 'DOWN',
};

export const EVENT_TYPES = {
  MOVE: 'MOVE',
  CHANGE_DIRECTION: 'CHANGE_DIRECTION',
  SPEED_BOOST: 'SPEED_BOOST',
};

export const UNIT = 20;
export const SPEED_BOOST_MULTIPLIER = 2;

export const KEY_TO_DIRECTION = {
  37: DIRECTIONS.LEFT,
  38: DIRECTIONS.UP,
  39: DIRECTIONS.RIGHT,
  40: DIRECTIONS.DOWN,
  87: DIRECTIONS.UP,
  65: DIRECTIONS.LEFT,
  83: DIRECTIONS.DOWN,
  68: DIRECTIONS.RIGHT,
};

export const GAME_STATES = {
  PLAYING: 'PLAYING',
  PAUSED: 'PAUSED',
  GAME_OVER: 'GAME_OVER',
};

export const PLAY_CONTAINER_ID = 'play-container';

export const DEFAULT_TIMER = 5;
