import { PLAY_CONTAINER_ID, UNIT } from './constants';

function getContainerDimensions(el) {
  const top = el.clientTop;
  const left = el.clientLeft;
  const width = el.clientHeight;
  const height = el.clientWidth;

  return { top, left, width, height };
}

export function getWindowBoundaries() {
  const el = document.getElementById(PLAY_CONTAINER_ID);
  if (!el) return { x1: 0, x2: 0, y1: 0, y2: 0 };

  const { left, width, top, height } = getContainerDimensions(el);

  const x1 = 0;
  const y1 = 0;
  const x2 = width - UNIT;
  const y2 = height - UNIT;

  return { x1, x2, y1, y2 };
}

export function getRandomPositionOnScreen(type) {
  const el = document.getElementById(PLAY_CONTAINER_ID);
  if (!el) return 100;

  const { left, width, top, height } = getContainerDimensions(el);

  if (type === 'x') {
    return getRndInteger(left, left + width - UNIT);
  } else if (type === 'y') {
    return getRndInteger(top, top + height - UNIT);
  }
}

function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}
