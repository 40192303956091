import React from 'react';
import { useHistory } from 'react-router-dom';

const styles = {
  container: {
    height: 400,
    width: 400,
    border: '2px solid white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 40,
    color: 'white',
  },
};

function Intro() {
  const history = useHistory();

  function onPlayClick() {
    history.push('/play');
  }
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Goop Trail</h1>
      <button onClick={onPlayClick}>Play</button>
    </div>
  );
}

export default Intro;
