import { useRef, useState, useEffect } from 'react';
import { getRandomPositionOnScreen } from '../utils';
import { UNIT, DEFAULT_TIMER } from '../utils/constants';

export default function useFruit(Snake) {
  const [timeBetweenFruit, setTimeBetweenFruit] = useState(DEFAULT_TIMER);
  const [x, setX] = useState(getRandomPositionOnScreen('x'));
  const [y, setY] = useState(getRandomPositionOnScreen('y'));
  const [height] = useState(UNIT);
  const [width] = useState(UNIT);

  const lose = useRef();

  useEffect(() => {
    startTimer();
  }, []);

  const timer = useRef(false);

  function setLoseFunction(loseFunction) {
    lose.current = loseFunction;
  }

  function handleTimer() {
    setTimeBetweenFruit(state => {
      if (state < 2) {
        lose.current();
        window.clearInterval(timer.current);
        timer.current = false;
        return DEFAULT_TIMER;
      }
      return state - 1;
    });
  }

  function startTimer() {
    timer.current = setInterval(handleTimer, 1000);
  }

  const xRef = useRef(x);
  const yRef = useRef(y);

  function checkCollided(a, b) {
    const diff = Math.abs(a - b);
    if (diff <= UNIT) return true;
    return false;
  }

  function checkSnakeCollision() {
    return (
      checkCollided(xRef.current, Snake.xRef.current) &&
      checkCollided(yRef.current, Snake.yRef.current)
    );
  }

  function resetPosition() {
    setTimeBetweenFruit(DEFAULT_TIMER);
    const newX = getRandomPositionOnScreen('x');
    const newY = getRandomPositionOnScreen('y');
    setX(newX);
    setY(newY);
    xRef.current = newX;
    yRef.current = newY;
    Snake.addTail();
  }

  function update() {
    if (checkSnakeCollision()) {
      resetPosition();
    }
  }

  return {
    x,
    y,
    height,
    width,
    update,
    timeBetweenFruit,
    setLoseFunction,
    lose,
    startTimer,
  };
}
