import { useEffect, useRef, useState } from 'react';
import { getWindowBoundaries } from '../utils';
import { DIRECTIONS, SPEED_BOOST_MULTIPLIER, UNIT } from '../utils/constants';

const DEFAULT_X = 0;
const DEFAULT_Y = 0;
const DEFAULT_HEIGHT = UNIT;
const DEFAULT_WIDTH = UNIT;

const MOVEMENT_SPEED = UNIT;

export default function useSnake() {
  const [x, setX] = useState(DEFAULT_X);
  const [y, setY] = useState(DEFAULT_Y);
  const [height, setHeight] = useState(DEFAULT_HEIGHT);
  const [width, setWidth] = useState(DEFAULT_WIDTH);
  const direction = useRef(DIRECTIONS.RIGHT);
  const [tail, setTail] = useState([]);
  const lose = useRef();
  const speedBoost = useRef(false);

  const xRef = useRef(x);
  const yRef = useRef(y);

  function reset() {
    setX(DEFAULT_X);
    xRef.current = DEFAULT_X;
    yRef.current = DEFAULT_Y;
    setY(DEFAULT_Y);
    setHeight(DEFAULT_HEIGHT);
    setWidth(DEFAULT_WIDTH);
    setTail([]);
  }

  function changeDirection(newDirection) {
    if (
      newDirection === DIRECTIONS.RIGHT &&
      direction.current === DIRECTIONS.LEFT
    )
      return;
    if (
      newDirection === DIRECTIONS.LEFT &&
      direction.current === DIRECTIONS.RIGHT
    )
      return;
    if (newDirection === DIRECTIONS.UP && direction.current === DIRECTIONS.DOWN)
      return;
    if (newDirection === DIRECTIONS.DOWN && direction.current === DIRECTIONS.UP)
      return;
    direction.current = newDirection;
  }

  function updateTails() {
    setTail(state => {
      const newTail = state;
      for (let i = 0; i < state.length; i++) {
        const iTail = state[i];
        if (i === 0) {
          iTail.previousX = iTail.x;
          iTail.previousY = iTail.y;
          iTail.x = xRef.current;
          iTail.y = yRef.current;
        } else {
          iTail.previousX = iTail.x;
          iTail.previousY = iTail.y;
          iTail.x = state[i - 1].previousX;
          iTail.y = state[i - 1].previousY;
        }

        newTail.splice(i, 1, iTail);
      }

      return newTail;
    });
  }

  function checkForSelfCollision() {
    const collided = tail.find(
      item => item.x === xRef.current && item.y === yRef.current,
    );

    if (collided) {
      thisLose();
    }
  }

  function getMovementSpeed() {
    if (speedBoost.current) return MOVEMENT_SPEED * SPEED_BOOST_MULTIPLIER;
    return MOVEMENT_SPEED;
  }

  function move() {
    const speed = getMovementSpeed();
    switch (direction.current) {
      case DIRECTIONS.UP:
        setY(state => state - speed);
        yRef.current = yRef.current - speed;
        break;
      case DIRECTIONS.DOWN:
        setY(state => state + speed);
        yRef.current = yRef.current + speed;
        break;
      case DIRECTIONS.LEFT:
        setX(state => state - speed);
        xRef.current = xRef.current - speed;
        break;
      case DIRECTIONS.RIGHT:
        setX(state => state + speed);
        xRef.current = xRef.current + speed;
        break;
      default:
        break;
    }
  }

  function checkEdgeCollision() {
    const { x1, x2, y1, y2 } = getWindowBoundaries();

    if (x < x1) {
      setX(x2);
      xRef.current = x2;
    } else if (x > x2) {
      setX(x1);
      xRef.current = x1;
    } else if (y > y2) {
      setY(y1);
      yRef.current = y1;
    } else if (y < y1) {
      setY(y2);
      yRef.current = y2;
    }
  }

  function update() {
    updateTails();
    move();
  }

  useEffect(() => {
    checkEdgeCollision();
    checkForSelfCollision();
  }, [x, y]);

  function addTail() {
    setTail(state => {
      const newTail = state.slice(0);
      newTail.push({
        x: tail.length ? tail[tail.length - 1].previousX : xRef.current,
        y: tail.length ? tail[tail.length - 1].previousY : yRef.current,
        height,
        width,
      });
      return newTail;
    });
  }

  function setLoseFunction(loseFunction) {
    lose.current = loseFunction;
  }

  function thisLose() {
    lose.current();
  }

  function toggleSpeedBoost() {
    speedBoost.current = !speedBoost.current;
  }

  return {
    x,
    y,
    height,
    width,
    reset,
    changeDirection,
    update,
    yRef,
    xRef,
    tail,
    addTail,
    setLoseFunction,
    toggleSpeedBoost,
  };
}
