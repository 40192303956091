import { useEffect, useMemo, useRef, useState } from 'react';
import useFruit from '../../classes/Fruit';
import useSnake from '../../classes/Snake';
import useGame from '../../game';
import { GAME_STATES } from '../../utils/constants';

const styles = {
  container: {
    height: 400,
    width: 400,
    border: '5px solid white',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 40,
  },
};

function Play() {
  const Snake = useSnake();
  const Fruit = useFruit(Snake);
  const { initGameLoop, gameState, changeGameState } = useGame(
    Snake,
    true,
    Fruit,
  );

  useEffect(() => {
    initGameLoop();
  }, []);

  function onTryAgain() {
    changeGameState(GAME_STATES.PLAYING);
    Snake.reset();
    Fruit.startTimer();
  }

  return (
    <div id="play-container" style={styles.container}>
      {gameState === GAME_STATES.GAME_OVER && (
        <>
          <h1 style={{ color: 'white' }}>GAME OVER</h1>
          <h4 style={{ color: 'white' }}>Score: {Snake.tail.length}</h4>
          <button
            style={{
              marginTop: 20,
              backgroundColor: 'white',
              border: 0,
              height: 40,
              borderRadius: 3,
              outline: 'none',
            }}
            onClick={onTryAgain}
          >
            Try Again
          </button>
        </>
      )}
      {(gameState === GAME_STATES.PLAYING ||
        gameState === GAME_STATES.PAUSED) && (
        <>
          <h3
            style={{
              color: 'white',
              position: 'absolute',
              top: -10,
              left: 10,
              zIndex: 9999,
            }}
          >
            Score: {Snake.tail.length}
          </h3>
          <h3
            style={{
              color: 'white',
              position: 'absolute',
              top: -10,
              right: 10,
              zIndex: 9999,
            }}
          >
            Time: {Fruit.timeBetweenFruit}
          </h3>
          {Snake && (
            <>
              <div
                style={{
                  position: 'absolute',
                  top: Snake.y,
                  left: Snake.x,
                  height: Snake.height,
                  width: Snake.width,
                  backgroundColor: '#07ff1a',
                }}
              />
              {Snake.tail.map(item => (
                <div
                  style={{
                    position: 'absolute',
                    top: item.y,
                    left: item.x,
                    height: item.height,
                    width: item.width,
                    backgroundColor: '#07ff1a',
                  }}
                />
              ))}
            </>
          )}
          {Fruit && (
            <div
              style={{
                position: 'absolute',
                top: Fruit.y,
                left: Fruit.x,
                height: Fruit.height,
                width: Fruit.width,
                backgroundColor: 'red',
              }}
            />
          )}
        </>
      )}
    </div>
  );
}

export default Play;
