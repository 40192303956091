import { useEffect, useRef, useState } from 'react';
import {
  DIRECTIONS,
  EVENT_TYPES,
  GAME_STATES,
  KEY_TO_DIRECTION,
} from './utils/constants';

// const REFRESH_RATE = 41.6;
const REFRESH_RATE = 60;

export default function useGame(player, hasMovement, entity) {
  const loop = useRef(null);
  const queuedEvents = useRef([]);
  const [gameState, setGameState] = useState(GAME_STATES.PLAYING);
  const gameStateRef = useRef(gameState);

  function gameLoop() {
    if (gameStateRef.current !== GAME_STATES.PLAYING) return;
    const event = queuedEvents.current[0];
    switch (event && event.type) {
      case EVENT_TYPES.CHANGE_DIRECTION:
        player.changeDirection(event.direction);
        queuedEvents.current.splice(0, 1);
        break;
      case EVENT_TYPES.SPEED_BOOST:
        player.toggleSpeedBoost();
        queuedEvents.current.splice(0, 1);
        break;
      default:
        // TODO
        break;
    }

    player.update();
    if (entity) {
      entity.update();
    }
  }

  function handleKeyUp(e) {
    if (e.keyCode === 32) {
      queuedEvents.current.push({
        type: EVENT_TYPES.SPEED_BOOST,
      });
    }
  }

  function handleKeyDown(e) {
    if (e.keyCode === 32) {
      queuedEvents.current.push({
        type: EVENT_TYPES.SPEED_BOOST,
      });
    }
    if (e.keyCode === 80) {
      // SPACE WAS PRESSED
      setGameState(state =>
        state === GAME_STATES.PLAYING
          ? GAME_STATES.PAUSED
          : GAME_STATES.PLAYING,
      );

      gameStateRef.current =
        gameStateRef.current === GAME_STATES.PLAYING
          ? GAME_STATES.PAUSED
          : GAME_STATES.PLAYING;
    }
    if (KEY_TO_DIRECTION[e.keyCode]) {
      queuedEvents.current.push({
        type: EVENT_TYPES.CHANGE_DIRECTION,
        direction: KEY_TO_DIRECTION[e.keyCode],
      });
    }
  }

  function addMovementListeners() {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
  }

  function lose() {
    setGameState(GAME_STATES.GAME_OVER);
  }

  function initGameLoop() {
    loop.current = setInterval(gameLoop, REFRESH_RATE);
    player.setLoseFunction(lose);
    entity.setLoseFunction(lose);
    if (hasMovement) {
      addMovementListeners();
    }
  }

  return {
    initGameLoop,
    gameState,
    changeGameState: setGameState,
  };
}
