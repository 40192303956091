import { BrowserRouter as Router, Route } from 'react-router-dom';
import Intro from '../../pages/Intro';
import Play from '../../pages/Play';

function AppRouter() {
  return (
    <Router>
      <>
        <Route exact path="/" component={Intro} />
        <Route exact path="/play" component={Play} />
      </>
    </Router>
  );
}

export default AppRouter;
